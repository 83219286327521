// BuildInfo.tsx
import React, { useEffect, useState } from 'react';

interface BuildInfoType {
  buildMajor: number;
  buildMinor: number;
  buildRevision: number;
  buildTime: string;
}

export const BuildInfo: React.FC = () => {
  const [buildInfo, setBuildInfo] = useState<BuildInfoType | null>(null);

  useEffect(() => {
    fetch('/build-info.json')
      .then((response) => response.json())
      .then((data: BuildInfoType) => setBuildInfo(data))
      .catch((error) => console.error('Error loading build info:', error));
  }, []);

  const buildInfoStyle = {
    position: 'fixed',
    bottom: '10px', // Adjust as needed to leave space from the bottom
    right: '10px', // Adjust as needed to leave space from the right
    background: '#f0f0f0',
    padding: '10px',
    borderTop: '1px solid #ccc',
    fontSize: '12px',
    textAlign: 'center',
    zIndex: '1000', // Ensure it's above other content if needed
  };

  if (process.env.REACT_APP_ENV === 'production' || !buildInfo) {
    return null;
  }   

  return (
    <div style={buildInfoStyle}>
      Build v{buildInfo.buildMajor}.{buildInfo.buildMinor}.
      {buildInfo.buildRevision} -{' '}
      {new Date(buildInfo.buildTime).toLocaleString()}
    </div>
  );
};

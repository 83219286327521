import { Link, useLocation } from '@remix-run/react';
import '../styles/footer.css';
import { BuildInfo } from './BuildInfo/BuildInfo';

export const Footer = () => {
  const location = useLocation();

  return (
    <footer className={`rowArrangement ${location.pathname === '/uploads/projects' && "uploadsFooter"}`}>
      <div id="logoFooter">
        <p id="logoText">&copy; BLUESKEYE AI 2024 | All Rights Reserved</p>
      </div>
      <div id="footerButtonGroup">
        <Link className="footerButton" to={'/termsAndConditions'}>
          Terms and Conditions
        </Link>
        <Link className="footerButton" to={'/privacyPolicy'}>
          Privacy Policy
        </Link>
        <Link className="footerButton" to={'/cookiePolicy'}>
          Cookie Policy
        </Link>
      </div>
      <BuildInfo />
    </footer>
  );
};
